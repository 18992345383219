import cls from '@/helpers/cls';
import { LabelHTMLAttributes } from 'react';

export default function InputLabel({
  value,
  light,
  className = '',
  children,
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & { value?: string; light?: boolean }) {
  return (
    <label {...props} className={cls('block text-sm', light ? 'font-medium tracking-widest text-white' : 'text-gray-700', className)}>
      {value ? value : children}
    </label>
  );
}
